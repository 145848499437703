import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

const Main = styled.main`
  background: #58aedd;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: white;
    background: #333;
    padding: 2.3rem;
    text-align: center;
    border-radius: 0.5rem;
    font-size: 2.2rem;
    margin-bottom: 20px;
  }
`;

const IndexPage = () => {
  return (
    <Main>
      <h1>Bloinka! - Stay Social</h1>
      <a
        style={{ textDecoration: "none", color: "white", fontSize: "20pt" }}
        href="mailto:info@bloinka.com"
      >
        Email us: info@bloinka.com
      </a>
    </Main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Bloinka!</title>;
